import dayjs, { Dayjs } from "dayjs"
import { isProxy, toRaw } from "vue"

import { IChild } from "@/db"
import { maxAgeInYearsForDevelopmentalScreening } from "@/models/DevelopmentalScreening"
import { getSiteByCmiId } from "@/services/Site"
import { getDiagnosisOptions, getSexOptions } from "@/utils/Options"
import { gettext } from "@/utils/Translation"
import { convertDisplayList } from "@/utils/Utilities"

export class Child implements IChild {
  id: number
  cmiId?: number
  createdDuringTraining: boolean
  hasDiagnosis: boolean
  dateOfAdmission?: Date
  diagnoses: [string]
  dob: Date
  dobEstimated: boolean
  firstName: string
  lastName?: string
  sex: string
  isAgedOutOfEarlyidAssessment: boolean
  nextAnemiaAssessmentDate?: Date
  nextEarlyidAssessmentDate?: Date
  nextGrowthAssessmentDate?: Date
  nextMealtimeAssessmentDate?: Date
  nextBestPracticesAssessmentDate?: Date
  isAgedOutOfBestPracticesAssessment?: boolean
  nextMealtimeAssessmentType?: string
  siteId: number
  typeOfCare: string
  typeOfService: string

  constructor(data: IChild) {
    for (const [key, value] of Object.entries(data)) {
      this[key] = value
    }
  }

  get fullName() {
    // XXX: retrofit with logic for inverting order for Asian countries
    return this.lastName ? `${this.firstName} ${this.lastName}`.trim() : this.firstName
  }

  get sexDisplay() {
    return convertDisplayList(getSexOptions())[this.sex]
  }

  get diagnosesDisplay() {
    if (!this.diagnoses || !this.diagnoses.length) {
      return ""
    }
    const formatter = new Intl.ListFormat(gettext.current, { type: "unit" })
    const map = convertDisplayList(getDiagnosisOptions())
    const diagnoses = this.diagnoses.map(dx => map[dx])
    return formatter.format(diagnoses)
  }

  get usesCommunityRecs() {
    // Default to using community recs.
    return this.typeOfService !== "residential"
  }

  // Return a simple JS object with the data that should be persisted in
  // IndexedDB and sent to the backend server.
  getDataToPersist({ includeNames = true } = {}) {
    const results = {
      id: this.id,
      cmiId: this.cmiId,
      createdDuringTraining: this.createdDuringTraining,
      hasDiagnosis: this.hasDiagnosis,
      dateOfAdmission: this.dateOfAdmission,
      diagnoses: isProxy(this.diagnoses) ? toRaw(this.diagnoses) : this.diagnoses,
      dob: this.dob,
      dobEstimated: this.dobEstimated,
      sex: this.sex,
      isAgedOutOfEarlyidAssessment: this.isAgedOutOfEarlyidAssessment,
      nextAnemiaAssessmentDate: this.nextAnemiaAssessmentDate,
      nextEarlyidAssessmentDate: this.nextEarlyidAssessmentDate,
      nextGrowthAssessmentDate: this.nextGrowthAssessmentDate,
      nextMealtimeAssessmentDate: this.nextMealtimeAssessmentDate,
      nextBestPracticesAssessmentDate: this.nextBestPracticesAssessmentDate,
      isAgedOutOfBestPracticesAssessment: this.isAgedOutOfBestPracticesAssessment,
      nextMealtimeAssessmentType: this.nextMealtimeAssessmentType,
      siteId: this.siteId,
      typeOfCare: this.typeOfCare,
      typeOfService: this.typeOfService,
    }
    if (includeNames) {
      results["firstName"] = this.firstName
      results["lastName"] = this.lastName
    }
    return results
  }

  /* Returns a number representing age in months, based on today or
  arg asOf. By default, returns a float number representing fractions of a
  month. Set asFloat=false to return an integer of completed months.
  */
  getAgeInMonths(asOf?: Date | Dayjs, asFloat = true): number {
    return dayjs(asOf || dayjs()).diff(this.dob, 'month', asFloat)
  }

  /* Returns a number representing age in years, based on today or
  arg asOf. By default, returns just completed years. Set asFloat=true
  to return a float including fractions of a year.
  */
  getAgeInYears(asOf?: Date | Dayjs, asFloat = false): number {
    return dayjs(asOf || dayjs()).diff(this.dob, 'year', asFloat)
  }

  /*
  * Given a date, return a nicely formatted age (diff btw that data and child's dob)
  * . e.g. 3y 4m
  * - for kids under 1 week, return the age in days
  * - for kids under 2 months, return the age in weeks
  * - for kids under 1 year, return age in months and weeks
  * - for kids under 2 years, return age in months
  * - otherwise, return the age in years and months (rounded down)
  */
  // XXX: i18n me
  getNiceAge(asOf: Date) {
    const doa = dayjs(asOf)
    let result: string
    const years = doa.diff(this.dob, "year")
    let months = doa.diff(this.dob, "month")
    if (years > 1) {
      result = `${years}y`
      months -= years * 12
      if (months) {
        result += ` ${months}m`
      }
      return result
    }
    if (months > 2) {
      return `${months}m`
    }
    const weeks = doa.diff(this.dob, "week")
    if (weeks) {
      return `${weeks}w`
    }
    return `${doa.diff(this.dob, "days")}d`
  }

  async getSite() {
    return await getSiteByCmiId(this.siteId)
  }

  /* Return the due date for child's next growth assessment based on our
  age-based schedule. This should be called as growth assessments are
  saved; it continually adjusts based on their answers. It assumes that
  the most recent growth assessment was completed on the date provided through
  asOf.

  After the baseline assessment, the rest of the assessments will be
  relative to the child's birthday:
  • 2 months
  • 4 months
  • 6 months*
  • 9 months*
  • 12 months*
  • 15 months
  • 18 months*
  • 2 years
  • 2 1/2 years
  • Annually from 3-21
  (Asterisk indicates mealtime as well as growth; updated elsewhere.)

  But concerns surfaced by the individual assessments could make a follow
  up assessment happen sooner. An optional follow up interval is passed in
  as followupAssessmentInterval: an object with keys weeks or months (which
  could be null). We test for that at the end, but ensure that we don't
  inadvertently delay the followup assessment because it would be later than
  the normal schedule.
  */
  getNextGrowthAssessmentDate(asOf: Date, followupAssessmentInterval?: object) {
    const today = new Date()

    // If today is within this threshold of the next assessment date, count
    // it as that assessment and skip to the next one.
    const thresholdInDays = 14
    let newDueDate: Date
    const adjustedAgeInMonths = this.getAgeInMonths(dayjs(asOf).add(thresholdInDays, "day"))

    const milestones = [2, 4, 6, 9, 12, 15, 18, 24, 30]
    const dob = dayjs(this.dob)
    for (let i = 0; i < milestones.length; i++) {
      const milestone = milestones[i]
      if (adjustedAgeInMonths < milestone) {
        newDueDate = dob.add(milestone, "month").toDate()
        break
      }
    }
    if (!newDueDate) {
      // We're older than 2.5 years: use birthdays
      let newDueDateAsDayjs = dob.add(this.getAgeInYears(asOf) + 1, "year")
      if (newDueDateAsDayjs.diff(today, "day") <= thresholdInDays) {
        newDueDateAsDayjs = newDueDateAsDayjs.add(1, "year")
      }
      newDueDate = newDueDateAsDayjs.toDate()
    }
    // Are there problems that require follow up assessments outside of the
    // typical schedule?
    if (followupAssessmentInterval) {
      // This is a little hacky since we express our followupAssessmentInterval
      // as an object with a plural key (weeks or months) but the DayJS API
      // wants the singular form when adding.
      const unit = "weeks" in followupAssessmentInterval ? "week" : "month"
      const interval = followupAssessmentInterval[unit + "s"]
      const possibleFollowUp = dayjs(today).add(interval, unit).toDate()
      newDueDate = new Date(Math.min(newDueDate.valueOf(), possibleFollowUp.valueOf()))
    }
    return newDueDate
  }

  // We only use needsFollowUp if the child is aged out of the normal set of
  // assessment ages.
  getNextFeedingScreeningDate(asOf: Date, needsFollowUp: boolean): Date | null {
    const today = new Date()

    // If today is within this threshold of the next assessment date, count
    // it as that assessment and skip to the next one.
    const thresholdInDays = 14
    const adjustedAgeInMonths = this.getAgeInMonths(dayjs(asOf).add(thresholdInDays, "day"))

    const milestones = [6, 9, 12, 18, 24, 36]
    const dob = dayjs(this.dob)
    for (let i = 0; i < milestones.length; i++) {
      const milestone = milestones[i]
      if (adjustedAgeInMonths < milestone) {
        return dob.add(milestone, "month").toDate()
      }
    }
    // Still haven't gotten a date yet...
    if (needsFollowUp) {
      // We're older than 2.5 years: use birthdays
      let newDueDateAsDayjs = dob.add(this.getAgeInYears(asOf) + 1, "year")
      if (newDueDateAsDayjs.diff(today, "day") <= thresholdInDays) {
        newDueDateAsDayjs = newDueDateAsDayjs.add(1, "year")
      }
      return newDueDateAsDayjs.toDate()
    }
    else {
      return null
    }
  }

  getNextBestPracticesDate(asOf: Date): Date | null {
    const today = new Date()

    // If today is within this threshold of the next assessment date, count
    // it as that assessment and skip to the next one.
    const thresholdInDays = 14
    const adjustedAgeInMonths = this.getAgeInMonths(dayjs(asOf).add(thresholdInDays, "day"))

    // Age out at 6 years.
    if (adjustedAgeInMonths >= 6 * 12) return

    const milestones = [2, 4, 6, 9, 12, 15, 18, 24]
    const dob = dayjs(this.dob)
    for (let i = 0; i < milestones.length; i++) {
      const milestone = milestones[i]
      if (adjustedAgeInMonths < milestone) {
        return dob.add(milestone, "month").toDate()
      }
    }
    // Still haven't gotten a date yet...
    // We're older than 2 years: use birthdays
    let newDueDateAsDayjs = dob.add(this.getAgeInYears(asOf) + 1, "year")
    if (newDueDateAsDayjs.diff(today, "day") <= 60) {
      newDueDateAsDayjs = newDueDateAsDayjs.add(1, "year")
    }
    return newDueDateAsDayjs.toDate()
  }

  // Updates nextEarlyidAssessmentDate and/or isAgedOutOfEarlyidAssessment
  // but doesn't save to database or upload!
  updateNextEarlyidAssessmentDate(asOf?: Date) {
    const asOfAsDayJs = dayjs(asOf)
    // Three brackets for age: ≤ 24 months; (2 yrs, 6 yrs]; > 6 yrs.
    const currentAgeInMonths = this.getAgeInMonths(asOfAsDayJs)
    const developmentalScreeningCutoffInMonths = maxAgeInYearsForDevelopmentalScreening * 12
    if (currentAgeInMonths >= developmentalScreeningCutoffInMonths) {
      // Child has aged out of developmental screening
      this.nextEarlyidAssessmentDate = null
      this.isAgedOutOfEarlyidAssessment = true
    }
    else if (currentAgeInMonths >= 24) {
      // For children over 24 months of age, next assessment date is simply today + 6 months
      this.nextEarlyidAssessmentDate = asOfAsDayJs.add(6, "month").toDate()
    }
    else {
      // SWYC kids use specific milestones
      const milestonesInMonths = [
        2, 4, 6, 9, 12, 15, 18,              // SWYC is up to 23 months
        24, 30, 36, 42, 48, 54, 60, 66, 72,  // WAG milestones (but not used as is).
      ]
      // If asOf is within this threshold of the next assessment date, count
      // it as that assessment and skip to the next one.
      const thresholdInDays = 14
      const adjustedAgeInMonths = this.getAgeInMonths(asOfAsDayJs.add(thresholdInDays, "day"))
      for (const milestone of milestonesInMonths) {
        if (adjustedAgeInMonths < milestone) {
          this.nextEarlyidAssessmentDate = dayjs(this.dob).add(milestone, "month").toDate()
          break
        }
      }
    }
  }
}
