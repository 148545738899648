import { db } from "@/db"
import * as api from "@/services/Api"
import {
  getAccountInfo,
  setAccountInfo,
  setStalenessNotificationTime,
  setUserLoggedInStatus,
} from "@/utils/GlobalState"
import { resetSession } from "@/utils/Session"
import { getCookie, isEmpty, isOnline } from "@/utils/Utilities"

/**
 * Unless @force is true, though, first check to see if there are any pending
 * uploads that haven't been dealt with. If so, throw an exception. If @force
 * is true, or if there are no pending uploads, log out user and delete all
 * local data. Note that if the user is offline or there are server errors
 * resulting from the logout process, we don't really care.
 * @args [bool]  force - should we force the deletion of all data and log out?
 */
export async function logout(force = false) {
  if (!force) {
    const pending = await db.pendingUploads.count()
    if (pending) {
      const error = new Error(pending.toString())
      error.name = "PENDING_UPLOADS"
      throw error
    }
  }
  await wipeSlate()
  setUserLoggedInStatus(false)
  if (!isOnline()) {
    return
  }
  return await api.logout()
}

/**
 * Delete IndexedDB and user-specific Local Storage data
 */
export async function wipeSlate() {
  setAccountInfo()
  setStalenessNotificationTime()
  resetSession()
  db.tables.forEach(async (table) => await table.clear())
}

export async function updateCachedAccountInfo() {
  try {
    const accountInfo = await api.getOwnAccount()
    setAccountInfo(!isEmpty(accountInfo) ? accountInfo : null)
  } catch (e) {
    // This is assumed to be called immediately after login, so we should
    // have connectivity and credentials. Hard to imagine why we would run into issues.
    console.log(e)
  }
}

/**
 * Retrieve an account from the server. Either both site and account ids are specified,
 * or neither (i.e. retrieve my own account). If it's malformed (just one or the other
 * are given), just return null.
 */
export async function getAccount(siteCmiId: number, accountCmiId: number) {
  if (siteCmiId && accountCmiId) {
    return await api.getAccountByCmiId(siteCmiId, accountCmiId)
  }
  if (!siteCmiId && !accountCmiId) {
    return await api.getOwnAccount()
  }
}

/**
 * Update an account on the server. Either both site and account ids are specified,
 * or neither (i.e. retrieve my own account). If it's malformed (just one or the other
 * are given), just return null. Either way, data needs to be supplied, with fields
 * firstName, lastName, email.
 */
export async function updateAccount(data: object, siteCmiId: number, accountCmiId: number) {
  if (siteCmiId && accountCmiId) {
    return await api.updateAccountByCmiId(siteCmiId, accountCmiId, data)
  }
  if (!siteCmiId && !accountCmiId) {
    return await api.updateOwnAccount(data)
  }
}

/**
 * Update a user's password on the server. Either both site and account ids are specified,
 * or neither (i.e. change own password). If it's malformed (just one or the other
 * are given), just return null. Either way, data needs to be supplied, with fields
 * old_password, new_password.
 */
export async function changePassword(data: object, siteCmiId: number, accountCmiId: number) {
  if (siteCmiId && accountCmiId) {
    return await api.changeOthersPassword(siteCmiId, accountCmiId, data)
  }
  if (!siteCmiId && !accountCmiId) {
    return await api.changeOwnPassword(data)
  }
}

export async function getAgreementDocumentList() {
  return await api.getAgreementDocuments()
}

export async function uploadAgreements(ids: Array<number>) {
  const munged = []
  for (const id of ids) {
    munged.push({ id })
  }
  const data = await api.uploadAgreements(munged)
  return data.hasOutstandingAgreements
}

export function shouldShowIntro() {
  return !getCookie("skipIntro")
}

export function hasOutstandingAgreements() {
  const accountInfo = getAccountInfo()
  return accountInfo?.hasOutstandingAgreements
}

export function suppressIntroInFuture() {
  document.cookie = "skipIntro=1"
}

export function getCurrentUsersName() {
  const accountInfo = getAccountInfo()
  return `${accountInfo["firstName"]} ${accountInfo["lastName"]}`
}
