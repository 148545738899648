import { isProxy, toRaw } from "vue"

import { db, IChild, IDevelopmentalScreening } from "@/db"
import { Child } from "@/models/Child"
import { DevelopmentalScreening } from "@/models/DevelopmentalScreening"
import * as api from "@/services/Api"
import { getChildById } from "@/services/Child"
import { handlePendingUploads, queuePendingUpload } from "@/services/Upload"
import { range } from "@/utils/Utilities"

/**
 * Return a developmental screening based on internal assessment id, or null. Wrapped if child is supplied.
 * If no id is provided, then child must be supplied; an incomplete assessment is assumed.
 */
export async function getDevelopmentalScreeningForChild(id?: number, child?: Child): Promise<IDevelopmentalScreening> {
  let obj: IDevelopmentalScreening
  if (id) {
    obj = await db.developmentalScreenings.get(id)
  }
  else {
    obj = await db.developmentalScreenings
      .where({ childId: child.id })
      .and(item => !item.isComplete)
      .last()
  }
  if (!obj) return
  return child ? new DevelopmentalScreening(child, obj) : obj
}

export function getFoldInSiteDevelopmentalScreeningsFromServer(siteId: number) {
  async function foldInSiteDevelopmentalScreeningsFromServer(assessments: Array<object>) {
    // Create a mapping of CMI ids to internal ids for both
    // - all currently cached children for this site
    // - all currently cached assessments for this site
    const childInternalIds = []
    const childCmiIdToInternalIds = {}
    const assessmentCmiIdToInternalIds = {}
    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => {
        results.forEach((child: IChild) => {
          childCmiIdToInternalIds[child.cmiId] = child.id
          childInternalIds.push(child.id)
        })
      })

    await db.developmentalScreenings
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<object>) => results.forEach((a: { cmiId: number, id: number }) => (assessmentCmiIdToInternalIds[a.cmiId] = a.id)))

    // Handle update/insert of the API results
    // Letting all the async calls run in parallel. Should happen fast enough that
    // it just works.
    assessments.forEach((assessment: IDevelopmentalScreening) => {
      // We only receive completed screenings!
      assessment.isComplete = true
      assessment.siteId = siteId
      // Swap backend child ids for internal ones, for consistency
      assessment["childId"] = childCmiIdToInternalIds[assessment["childId"]]
      if (assessment.cmiId in assessmentCmiIdToInternalIds) {
        db.developmentalScreenings.update(assessmentCmiIdToInternalIds[assessment.cmiId], assessment)
      }
      else {
        db.developmentalScreenings.add(assessment)
      }
    })
  }
  return foldInSiteDevelopmentalScreeningsFromServer
}

// Return *wrapped* instance of DevelopmentalScreening. Expects *wrapped* instance
// of child as arg.
export async function getLastDevelopmentalScreeningForChild(child: Child) {
  const ds = await db.developmentalScreenings
    .where({ childId: child.id })
    .filter(a => a.isComplete)
    .sortBy("dateOfAssessment")
  if (ds.length) {
    return new DevelopmentalScreening(child, ds[ds.length - 1])
  }
}

export async function getMostRecentDevelopmentalScreeningForEachChild(siteId: number) {
  const allAssessments = await db.developmentalScreenings
    .where({ siteId })
    .reverse()
    .sortBy("dateOfAssessment")
  const childrenAlreadySeen = new Set()
  const results = []
  // Only extract the first assessment we find for each child.
  allAssessments.forEach((a: IDevelopmentalScreening) => {
    if (!childrenAlreadySeen.has(a.childId)) {
      results.push(a)
      childrenAlreadySeen.add(a.childId)
    }
  })
  return results
}


/**
 * Replace all screenings for a child with what's on the server. After handling
 * pending updates, of course! Doesn't return anything.
 * @args [Number] childId - this is the *internal* id!
 */
export async function updateDevelopmentalScreeningsFromServer(childId: number) {
  try {
    handlePendingUploads()
  }
  catch (error) {
    console.log(error)
    return
  }
  // Determine child's CMI id
  const child = await getChildById(childId)
  if (!child.cmiId) {
    throw new Error("Can't poll for a child lacking cmi id!")
  }
  // Fetch from server
  const screenings = await api.getDevelopmentalScreeningsForChild(child.cmiId)

  // Remap the child ids to the internal one.
  screenings.forEach((s: IDevelopmentalScreening) => {
    s.isComplete = true
    s.childId = childId
    s.siteId = child.siteId
  })

  // Purge previous records
  await db.developmentalScreenings.where({ childId }).delete()

  // Update with the new
  await db.developmentalScreenings.bulkAdd(screenings)
}

/*
 * Given a local developmentalScreening id, look up the object in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * cached object's CMI id. If successful, return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} developmentalScreeningId - internal id for pulling from queue
 * @return {Object} developmentalScreening object from API
 */
export async function uploadDevelopmentalScreening(developmentalScreeningId: number) {
  const developmentalScreening = await getDevelopmentalScreeningForChild(developmentalScreeningId)
  // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
  const child = await getChildById(developmentalScreening.childId)
  if (!child.cmiId) {
    throw new Error("Can't upload a developmental screening for a child lacking a CMI id.")
  }
  developmentalScreening.childId = child.cmiId
  return api.uploadDevelopmentalScreening(developmentalScreening).then(async (data: IDevelopmentalScreening) => {
    // If a successful POST, update the internal CMI id
    if (!developmentalScreening.cmiId) {
      await db.developmentalScreenings.update(developmentalScreening.id, { cmiId: data.id, creatorName: data.creatorName })
    }
    return data
  })
}


/*
 * Given a local developmentalScreening id, look up the object in the pendingUploads queue
 * and retrieve the developmentalScreening from the database. If the assessment lacks a
 * cmi ID, abort the operation–the note will get uploaded as part of the rest of the
 * assessment. Otherwise, upload the entire notes object from the assessment.
 * If successful, remove the entry from the queue and return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} developmentalScreeningId - internal id for pulling from queue
 * @return {Object} developmentalScreening object from API
 */
export async function uploadDevelopmentalScreeningNote(developmentalScreeningId: number) {
  const screening = await getDevelopmentalScreeningForChild(developmentalScreeningId)
  if (screening.cmiId) {
    // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
    const child = await getChildById(screening.childId)
    if (!child.cmiId) {
      throw new Error("Can't upload a developmental screening for a child lacking a CMI id.")
    }
    screening.childId = child.cmiId
    return await api.uploadDevelopmentalScreening(screening, true)
  }
}

// Given an instance of DevelopmentalScreening, either add (if new) or update (if existing) in IDB.
// Note: replaces object entirely!
// Returns the IDB id for the object.
export async function createOrReplaceDevelopmentalScreening(screening: DevelopmentalScreening) {
  const saveableData = {} as IDevelopmentalScreening
  const fields = [
    "id",
    "cmiId",
    "isComplete",
    "childId",
    "siteId",
    "dueDate",
    "dateOfAssessment",
    "dateCreated",
    "creatorName",
    "notes",
    "modelName",
    "swycVersion",
    "hasReferrals",
  ]
  // SWYC fields
  fields.push(...range(1, 11).map(i => `dmQ${i}`))
  fields.push(...range(1, 13).map(i => `bpscQ${i}`))
  fields.push(...range(1, 19).map(i => `ppscQ${i}`))
  fields.push(...range(1, 11).map(x => `ecwanbQ${x}`))
  fields.push(...range(1, 3).map(x => `pcQ${x}`))
  fields.push(...range(1, 11).map(x => `fqQ${x}`))
  // WAG fields
  fields.push(...range(1, 25).map(x => `cf${x}`))

  fields.forEach((fieldName) => {
    if (fieldName in screening) {
      const value = screening[fieldName]
      saveableData[fieldName] = isProxy(value) ? toRaw(value) : value
    }
  })
  saveableData.childId = screening.child.id
  saveableData.siteId = screening.child.siteId
  const screeningId = await db.developmentalScreenings.put(saveableData)
  screening.id = screeningId
  return screeningId
}

export async function finalizeDevelopmentalScreening(child: Child, screening: DevelopmentalScreening) {
  screening.dueDate = child.nextEarlyidAssessmentDate || new Date()
  screening.hasReferrals = Boolean(screening.getReferrals().length)
  const screeningChanges = {
    hasReferrals: screening.hasReferrals,
    dueDate: screening.dueDate
  }

  // Update child's next due date?
  child.updateNextEarlyidAssessmentDate(screening.dateOfAssessment)
  const possiblyChangedChildFields = {
    nextEarlyidAssessmentDate: child.nextEarlyidAssessmentDate,
    isAgedOutOfEarlyidAssessment: child.isAgedOutOfEarlyidAssessment,
  }

  await db.transaction("rw", db.children, db.developmentalScreenings, db.pendingUploads, async () => {
    return Promise.all([
      db.developmentalScreenings.update(screening.id, screeningChanges),
      queuePendingUpload({ type: "developmentalScreening", localItemId: screening.id }),
      db.children.update(child.id, possiblyChangedChildFields),
      queuePendingUpload({ type: "child", localItemId: child.id }),
    ])
  })

}


// Given a wrapped Child instance and a date, determine the correct "model" for a new assessment for this child,
// save out a stub for it, and return a newly-instantiated DevelopmentalScreening object.
export async function createDevelopmentalScreening(child: Child, dateOfAssessment: Date): Promise<DevelopmentalScreening> {
  const screening = new DevelopmentalScreening(child, { dateOfAssessment } as IDevelopmentalScreening)
  screening.id = await createOrReplaceDevelopmentalScreening(screening)
  return screening
}

// Used in cases where a user is editing an existing screening and changes the
// assessment date. Replace most of what's there with nothing.
export async function replaceAssessment(oldScreening: DevelopmentalScreening, dateOfAssessment: Date) {
  const { id, child, cmiId = null } = oldScreening
  const screening = new DevelopmentalScreening(child, { dateOfAssessment } as IDevelopmentalScreening)
  screening.id = id
  screening.cmiId = cmiId
  await createOrReplaceDevelopmentalScreening(screening)
  return screening
}
