import { isProxy, toRaw } from "vue"

import { db, IBestPracticeAssessment, IChild, IFeedingScreening } from "@/db"
import { Child } from "@/models/Child"
import {
  ChildBestPracticeAssessment,
  FeedingScreening,
} from "@/models/Feeding"
import * as api from "@/services/Api"
import { getChildById } from "@/services/Child"
import { handlePendingUploads, queuePendingUpload } from "@/services/Upload"

/**
 * Return a feeding screening based on internal assessment id, or null.
  [Wrapped if child is supplied.]
 * If no id is provided, then child must be supplied; an incomplete assessment is assumed.
 */
export async function getFeedingScreeningForChild(id?: number, child?: Child): Promise<IFeedingScreening> {
  let obj: IFeedingScreening
  if (id) {
    obj = await db.feedingScreenings.get(id)
  }
  else {
    obj = await db.feedingScreenings
      .where({ childId: child.id })
      .and(item => !item.isComplete)
      .last()
  }
  if (!obj) return
  return child ? new FeedingScreening(child, obj) : obj
}

/**
 * - Create or replace an instance of a feedingScreening in IndexedDB and return its id.
 */
export async function createOrReplaceFeedingScreening(child: IChild, screening: IFeedingScreening): Promise<number> {
  const saveableData = {} as IFeedingScreening
  const fields = [
    "isComplete",
    "id",
    "childId",
    "siteId",
    "dateOfAssessment",
    "notes",
    "doesChildCommunicateHunger",
    "doesChildEatEnough",
    "mealtimeDuration",
    "doesChildShowSignsOfAspiration",
    "doesChildEatReluctantly",
    "isChildNotGainingWeight",
    "areMealtimesStressful",
    "doesCaregiverHaveMealtimeConcerns",
    "hasReferrals",

  ]
  fields.forEach((fieldName) => {
    if (fieldName in screening) {
      const value = screening[fieldName]
      saveableData[fieldName] = isProxy(value) ? toRaw(value) : value
    }
  })

  saveableData.siteId = child.siteId
  return await db.feedingScreenings.put(saveableData)
}

export async function finalizeFeedingScreening(child: IChild, assessment: IFeedingScreening) {
  if (isProxy(child)) {
    child = toRaw(child)
  }
  const childAsModel = new Child(child)
  const screeningAsModel = new FeedingScreening(childAsModel, assessment)
  assessment.hasReferrals = Boolean(screeningAsModel.referrals?.length)

  // Update child's next due date?
  const newDueDate = childAsModel.getNextFeedingScreeningDate(
    screeningAsModel.dateOfAssessment,
    screeningAsModel.isAtRiskOfFeedingDifficulties
  )

  await db.transaction("rw", db.children, db.feedingScreenings, db.pendingUploads, async () => {
    const promises = [
      db.feedingScreenings.update(assessment.id, { hasReferrals: assessment.hasReferrals }),
      queuePendingUpload({ type: "feedingScreening", localItemId: assessment.id })
    ]
    if (!assessment.dueDate) {
      assessment.dueDate = child.nextMealtimeAssessmentDate || new Date()
      promises.push(
        db.feedingScreenings.update(assessment.id, { dueDate: assessment.dueDate })
      )
    }

    if (child.nextMealtimeAssessmentDate !== newDueDate) {
      const changes = { nextMealtimeAssessmentDate: newDueDate }
      child.nextMealtimeAssessmentDate = newDueDate
      if (!newDueDate) {
        child.nextMealtimeAssessmentType = "aged-out"
        changes["nextMealtimeAssessmentType"] = "aged-out"
      }
      promises.push(
        db.children.update(child.id, changes),
        queuePendingUpload({ type: "child", localItemId: child.id })
      )
    }
    return Promise.all(promises)
  })
  return assessment.id
}

/**
 * Return an array of the screenings for this child (based on their internal id).
 * Assessments are sorted in chrono order.
 * @args {Number}   childId – this is the internal IndexedDB id, not from server
 * @return [Object]
 */
export async function getAllSavedFeedingScreeningsForChild(childId: number): Promise<Array<IFeedingScreening>> {
  return await db.feedingScreenings
    .where({ childId })
    .sortBy("dateOfAssessment")
}

// Return *wrapped* instance of FeedingScreening.
// Expects *wrapped* instance of child as arg.
export async function getLastSavedFeedingScreeningForChild(child: Child) {
  const assessments = await getAllSavedFeedingScreeningsForChild(child.id)
  const fs = assessments.filter(a => a.isComplete).pop()
  if (fs) {
    return new FeedingScreening(child, fs)
  }
}

/*
 * Given a feedingScreening id, look up the object in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * cached object's CMI id. If successful, return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} feedingScreeningId - internal id for pulling from queue
 * @return {Object} feedingScreening object from API
 */
export async function uploadFeedingScreening(feedingScreeningId: number) {
  const feedingScreening = await getFeedingScreeningForChild(feedingScreeningId)
  // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
  const child = await getChildById(feedingScreening.childId)
  if (!child.cmiId) {
    throw new Error("Can't upload a feeding screening for a child lacking a CMI id.")
  }
  feedingScreening.childId = child.cmiId
  delete feedingScreening.isComplete
  return api.uploadFeedingScreening(feedingScreening).then(async (data: IFeedingScreening) => {
    // If a successful POST, update the internal CMI id
    if (!feedingScreening.cmiId) {
      await db.feedingScreenings.update(feedingScreening.id, { cmiId: data.id })
    }
    return data
  })
}


/*
 * Given a local feedingScreening id, look up the object in the pendingUploads queue
 * and retrieve the screening from the database. If the screening lacks a
 * cmi ID, abort the operation–the note will get uploaded as part of the rest of the
 * screening. Otherwise, upload the entire notes object from the screening.
 * If successful, remove the entry from the queue and return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} feedingScreeningId - internal id for pulling from queue
 * @return {Object} feedingScreening object from API
 */
export async function uploadFeedingScreeningNote(feedingScreeningId: number) {
  const feedingScreening = await getFeedingScreeningForChild(feedingScreeningId)
  if (feedingScreening.cmiId) {
    // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
    const child = await getChildById(feedingScreening.childId)
    if (!child.cmiId) {
      throw new Error("Can't upload a feeding screening note for a child lacking a CMI id.")
    }
    feedingScreening.childId = child.cmiId
    return await api.uploadFeedingScreening(feedingScreening, true)
  }
}


/**
 * Replace all feeding screenings for a child with what's on the server. After handling
 * pending updates, of course! Doesn't return anything.
 * @args [Number] childId - this is the *internal* id!
 */
export async function updateFeedingScreeningsFromServer(childId: number): Promise<void> {
  try {
    handlePendingUploads()
  }
  catch (error) {
    console.log(error)
    return
  }
  // Determine child's CMI id
  const child = await getChildById(childId)
  if (!child.cmiId) {
    throw new Error("Can't poll for a child lacking cmi id!")
  }
  // Fetch from server
  const screenings = await api.getFeedingScreeningsForChild(child.cmiId)

  // Remap the child ids to the internal one.
  screenings.forEach((fs: IFeedingScreening) => {
    fs.childId = childId
    fs.isComplete = true
    fs.siteId = child.siteId
  })

  // Purge previous records
  await db.feedingScreenings.where({ childId }).delete()

  // Update with the new
  await db.feedingScreenings.bulkAdd(screenings)
}

export function getFoldInSiteFeedingScreeningsFromServer(siteId: number) {
  async function foldInSiteFeedingScreeningsFromServer(assessments: Array<object>) {
    // Create a mapping of CMI ids to internal ids for both
    // - all currently cached children for this site
    // - all currently cached assessments for this site
    const childInternalIds = []
    const childCmiIdToInternalIds = {}
    const screeningCmiIdToInternalIds = {}
    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => {
        results.forEach((child: IChild) => {
          childCmiIdToInternalIds[child.cmiId] = child.id
          childInternalIds.push(child.id)
        })
      })

    await db.feedingScreenings
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<IFeedingScreening>) => results.forEach((a: IFeedingScreening) => screeningCmiIdToInternalIds[a.cmiId] = a.id))

    // Handle update/insert of the API results
    // Letting all the async calls run in parallel. Should happen fast enough that
    // it just works.
    assessments.forEach((assessment: IFeedingScreening) => {
      // Swap backend child ids for internal ones, for consistency
      assessment["childId"] = childCmiIdToInternalIds[assessment["childId"]]
      assessment.isComplete = true
      assessment.siteId = siteId
      if (assessment.cmiId in screeningCmiIdToInternalIds) {
        db.feedingScreenings.update(screeningCmiIdToInternalIds[assessment.cmiId], assessment)
      }
      else {
        db.feedingScreenings.add(assessment)
      }
    })
  }
  return foldInSiteFeedingScreeningsFromServer
}

// =================
// == Best Practices
// =================


/**
 * Return a feeding screening based on internal assessment id, or null.
  [Wrapped if child is supplied.]
 * If no id is provided, then child must be supplied; an incomplete assessment is assumed.
 */
export async function getChildBestPracticeAssessmentForChild(id?: number, child?: Child): Promise<IBestPracticeAssessment> {
  let obj: IBestPracticeAssessment
  if (id) {
    obj = await db.childBestPracticeAssessments.get(id)
  }
  else {
    obj = await db.childBestPracticeAssessments
      .where({ childId: child.id })
      .and(item => !item.isComplete)
      .last()
  }
  if (!obj) return
  return child ? new ChildBestPracticeAssessment(child, obj) : obj
}

/**
 * - Create or replace an instance of a feedingScreening in IndexedDB and return its id.
 */
export async function createOrReplaceChildBestPracticeAssessment(child: IChild, screening: IBestPracticeAssessment): Promise<number> {
  const saveableData = {} as IBestPracticeAssessment
  const fields = [
    "isComplete",
    "id",
    "cmiId",
    "childId",
    "siteId",
    "dateOfAssessment",
    "dueDate",
    "notes",
    "tools",
    "textures",
    "feeder",
    "bf",
    "rf1",
    "rf2",
    "rf3",
    "rf4",
    "rf5",
    "rf6",
    "rf7",
    "rf8",
    "fp1",
    "fp2",
    "fp3",
    "fp4",
    "fp5",
    "mf",
    "mff",
    "fg1a",
    "fg2a",
    "fg1b",
    "fg2b",
    "fg2c",
    "fg3a",
    "fg4",
    "fg5",
    "fg6",
    "fg7",
    "fg8",
    "hasReferrals",
  ]
  fields.forEach((fieldName) => {
    if (fieldName in screening) {
      const value = screening[fieldName]
      saveableData[fieldName] = isProxy(value) ? toRaw(value) : value
    }
  })

  saveableData.childId = child.id
  saveableData.siteId = child.siteId
  return await db.childBestPracticeAssessments.put(saveableData)
}

// Return *wrapped* instance of ChildBestPracticeAssessment. Expects *wrapped* instance
// of child as arg.
export async function getLastChildBestPracticeAssessmentForChild(child: Child) {
  const ds = await db.childBestPracticeAssessments
    .where({ childId: child.id })
    .filter(a => a.isComplete)
    .sortBy("dateOfAssessment")
  if (ds.length) {
    return new ChildBestPracticeAssessment(child, ds[ds.length - 1])
  }
}

export async function finalizeBestPracticesAssessment(child: IChild, assessment: IBestPracticeAssessment) {
  if (isProxy(child)) {
    child = toRaw(child)
  }
  const childAsModel = new Child(child)

  // Update child's next due date?
  const newDueDate = childAsModel.getNextBestPracticesDate(assessment.dateOfAssessment)

  await db.transaction("rw", db.children, db.childBestPracticeAssessments, db.pendingUploads, async () => {
    const promises = [
      db.childBestPracticeAssessments.update(assessment.id, { hasReferrals: assessment.hasReferrals }),
      queuePendingUpload({ type: "childBestPracticeAssessment", localItemId: assessment.id })
    ]
    if (!assessment.dueDate) {
      assessment.dueDate = child.nextBestPracticesAssessmentDate || new Date()
      promises.push(
        db.childBestPracticeAssessments.update(assessment.id, { dueDate: assessment.dueDate })
      )
    }


    if (child.nextBestPracticesAssessmentDate !== newDueDate) {
      const changes = { nextBestPracticesAssessmentDate: newDueDate }
      child.nextBestPracticesAssessmentDate = newDueDate
      if (!newDueDate) {
        child.isAgedOutOfBestPracticesAssessment = true
        changes["isAgedOutOfBestPracticesAssessment"] = true
      }
      promises.push(
        db.children.update(child.id, changes),
        queuePendingUpload({ type: "child", localItemId: child.id })
      )
    }
    return Promise.all(promises)
  })
  return assessment.id
}


/*
 * Given an assessment id, look up the object in the pendingUploads queue and attempt
 * the upload. If successful, remove the entry from the queue and update
 * cached object's CMI id. If successful, return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} assessmentId - internal id for pulling from queue
 * @return {Object} BestPracticeAssessment object from API
 */
export async function uploadBestPractice(assessmentId: number) {
  const assessment = await getChildBestPracticeAssessmentForChild(assessmentId)
  // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
  const child = await getChildById(assessment.childId)
  if (!child.cmiId) {
    throw new Error("Can't upload a feeding screening for a child lacking a CMI id.")
  }
  assessment.childId = child.cmiId
  delete assessment.isComplete
  return api.uploadBestPracticeAssessment(assessment).then(async (data: IBestPracticeAssessment) => {
    // If a successful POST, update the internal CMI id
    if (!assessment.cmiId) {
      await db.childBestPracticeAssessments.update(assessment.id, { cmiId: data.id })
    }
    return data
  })
}


/*
 * Given a local assessment id, look up the object in the pendingUploads queue
 * and retrieve the screening from the database. If the screening lacks a
 * cmi ID, abort the operation–the note will get uploaded as part of the rest of the
 * screening. Otherwise, upload the entire notes object from the screening.
 * If successful, remove the entry from the queue and return API's version of the object.
 * Allow all exceptions to trickle up.
 * @args {Number} assessmentId - internal id for pulling from queue
 * @return {Object} BestPracticeAssessment object from API
 */
export async function uploadBestPracticeNote(assessmentId: number) {
  const assessment = await getChildBestPracticeAssessmentForChild(assessmentId)
  if (assessment.cmiId) {
    // Look up child's CMI id and swap it in (replacing internal id) just for the upload.
    const child = await getChildById(assessment.childId)
    if (!child.cmiId) {
      throw new Error("Can't upload a feeding screening note for a child lacking a CMI id.")
    }
    assessment.childId = child.cmiId
    return await api.uploadBestPracticeAssessment(assessment, true)
  }
}

/**
 * Replace all BPAs for a child with what's on the server. After handling
 * pending updates, of course! Doesn't return anything.
 * @args [Number] childId - this is the *internal* id!
 */
export async function updateBestPracticeAssessmentsFromServer(childId: number): Promise<void> {
  try {
    handlePendingUploads()
  }
  catch (error) {
    console.log(error)
    return
  }
  // Determine child's CMI id
  const child = await getChildById(childId)
  if (!child.cmiId) {
    throw new Error("Can't poll for a child lacking cmi id!")
  }
  // Fetch from server
  const screenings = await api.getBestPracticeAssessmentsForChild(child.cmiId)

  // Remap the child ids to the internal one.
  screenings.forEach((fs: IBestPracticeAssessment) => {
    fs.childId = childId
    fs.isComplete = true
    fs.siteId = child.siteId
  })

  // Purge previous records
  await db.childBestPracticeAssessments.where({ childId }).delete()

  // Update with the new
  await db.childBestPracticeAssessments.bulkAdd(screenings)
}

export function getFoldInSiteBestPracticeAssessmentsFromServer(siteId: number) {
  async function foldInSiteBestPracticeAssessmentsFromServer(assessments: Array<object>) {
    // Create a mapping of CMI ids to internal ids for both
    // - all currently cached children for this site
    // - all currently cached assessments for this site
    const childInternalIds = []
    const childCmiIdToInternalIds = {}
    const assessmentCmiIdToInternalIds = {}
    await db.children
      .where({ siteId })
      .toArray()
      .then((results: Array<object>) => {
        results.forEach((child: IChild) => {
          childCmiIdToInternalIds[child.cmiId] = child.id
          childInternalIds.push(child.id)
        })
      })

    await db.childBestPracticeAssessments
      .where("childId")
      .anyOf(childInternalIds)
      .toArray()
      .then((results: Array<IBestPracticeAssessment>) => results.forEach((a: IBestPracticeAssessment) => assessmentCmiIdToInternalIds[a.cmiId] = a.id))

    // Handle update/insert of the API results
    // Letting all the async calls run in parallel. Should happen fast enough that
    // it just works.
    assessments.forEach((assessment: IBestPracticeAssessment) => {
      // Swap backend child ids for internal ones, for consistency
      assessment["childId"] = childCmiIdToInternalIds[assessment["childId"]]
      assessment.isComplete = true
      assessment.siteId = siteId
      if (assessment.cmiId in assessmentCmiIdToInternalIds) {
        db.childBestPracticeAssessments.update(assessmentCmiIdToInternalIds[assessment.cmiId], assessment)
      }
      else {
        db.childBestPracticeAssessments.add(assessment)
      }
    })
  }
  return foldInSiteBestPracticeAssessmentsFromServer
}
