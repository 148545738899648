import dayjs from "dayjs"

import * as devScreeningContent from "@/data/DevelopmentalScreeningContent"
import { IDevelopmentalScreening } from "@/db"
import { Child } from "@/models/Child"
import { isAssessmentEditable } from "@/models/Common"
import { gettext } from "@/utils/Translation.js"
import { isKnown, range, sum } from "@/utils/Utilities"

const { $gettext } = gettext

export const maxAgeInYearsForDevelopmentalScreening = 10

export class DevelopmentalScreening implements IDevelopmentalScreening {
  id: number
  child: Child
  // Since the criteria for whether a screening is done varies with the
  // type, encapsulate here for easier querying.
  isComplete: boolean

  _ageInDays: number
  _ageInMonths: number
  _ageInYears: number
  // _interpretations: { weight?: Interpretation, height?: Interpretation, headSize?: Interpretation, muac?: Interpretation }
  // _recommendations: Array<RecommendationItem>

  childId: number
  siteId: number
  cmiId?: number
  dateOfAssessment: Date
  dateCreated?: Date
  dueDate?: Date
  modelName: string
  hasReferrals: boolean

  // SWYC fields
  swycVersion?: string
  // Developmental Milestones
  dmQ1?: number
  dmQ2?: number
  dmQ3?: number
  dmQ4?: number
  dmQ5?: number
  dmQ6?: number
  dmQ7?: number
  dmQ8?: number
  dmQ9?: number
  dmQ10?: number

  // Baby pediatric symptom checklist
  bpscQ1?: number
  bpscQ2?: number
  bpscQ3?: number
  bpscQ4?: number
  bpscQ5?: number
  bpscQ6?: number
  bpscQ7?: number
  bpscQ8?: number
  bpscQ9?: number
  bpscQ10?: number
  bpscQ11?: number
  bpscQ12?: number

  // Preschool pediatric symptom checklist
  ppscQ1?: number
  ppscQ2?: number
  ppscQ3?: number
  ppscQ4?: number
  ppscQ5?: number
  ppscQ6?: number
  ppscQ7?: number
  ppscQ8?: number
  ppscQ9?: number
  ppscQ10?: number
  ppscQ11?: number
  ppscQ12?: number
  ppscQ13?: number
  ppscQ14?: number
  ppscQ15?: number
  ppscQ16?: number
  ppscQ17?: number
  ppscQ18?: number

  // Emotional changes
  ecwanbQ1?: number
  ecwanbQ2?: number
  ecwanbQ3?: number
  ecwanbQ4?: number
  ecwanbQ5?: number
  ecwanbQ6?: number
  ecwanbQ7?: number
  ecwanbQ8?: number
  ecwanbQ9?: number
  ecwanbQ10?: number

  // Family questions
  pcQ1?: number
  pcQ2?: number
  fqQ1?: number
  fqQ2?: number
  fqQ3?: number
  fqQ4?: number
  fqQ5?: number
  fqQ6?: number
  fqQ7?: number
  fqQ8?: number
  fqQ9?: number
  fqQ10?: number

  // WAG fields
  cf1?: number
  cf2?: number
  cf3?: number
  cf4?: number
  cf5?: number
  cf6?: number
  cf7?: number
  cf8?: number
  cf9?: number
  cf10?: number
  cf11?: number
  cf12?: number
  cf13?: number
  cf14?: number
  cf15?: number
  cf16?: number
  cf17?: number
  cf18?: number
  cf19?: number
  cf20?: number
  cf21?: number
  cf22?: number
  cf23?: number
  cf24?: number

  constructor(child: Child, data: IDevelopmentalScreening) {
    this.child = child instanceof Child ? child : new Child(child)
    this.siteId = child.siteId
    this.isComplete = false
    // Presumably a preexisting screening.
    if (data.modelName) {
      for (const [key, value] of Object.entries(data)) {
        this[key] = value
      }
    }
    // Presumably a nascent one with no other data yet. Determine modelName.
    else if (data.dateOfAssessment) {
      this.dateOfAssessment = data.dateOfAssessment
      const ageInMonths = this.child.getAgeInMonths(data.dateOfAssessment)
      const usesWag = true // As in, "are WAG questions an option for children of the appropriate age". This could become a site-level setting down the road.
      // Relying on external logic to catch out of range ages on either side.
      if (ageInMonths < 4) this.modelName = "Swyc2Months"
      else if (ageInMonths < 6) this.modelName = "Swyc4Months"
      else if (ageInMonths < 9) this.modelName = "Swyc6Months"
      else if (ageInMonths < 12) this.modelName = "Swyc9Months"
      else if (ageInMonths < 15) this.modelName = "Swyc12Months"
      else if (ageInMonths < 18) this.modelName = "Swyc15Months"
      else if (ageInMonths < 23) this.modelName = "Swyc18Months"
      else {
        if (usesWag) {
          const ageInYears = child.getAgeInYears(data.dateOfAssessment)
          if (ageInYears < 2) this.modelName = "Swyc24Months"
          else if (ageInYears < 5) this.modelName = "WashingtonGroup2to4Years"
          else this.modelName = "WashingtonGroup5to17Years"
        }
        else if (ageInMonths < 30) this.modelName = "Swyc24Months"
      }
      if (!this.modelName) {
        throw Error("No model name specified.")
      }
    }
  }

  get ageInMonths() {
    if (!this._ageInMonths && this.dateOfAssessment && this.child.dob) {
      this._ageInMonths = dayjs(this.dateOfAssessment).diff(this.child.dob, "month", true)
    }
    return this._ageInMonths
  }

  get niceAge() {
    if (this.dateOfAssessment) {
      return this.child.getNiceAge(this.dateOfAssessment)
    }
  }

  // Is child too young to perform an assessment (ie the SWYC)?
  static isChildTooYoung(child: Child, asOf?: Date) {
    // (SWYC 2-month form goes from 1 month to 4.)
    return child.getAgeInMonths(asOf) < 1
  }

  // Has child aged out of developmental screenings?
  // Note that this might become program-specific; the WAG goes up to 17y, but our programs might never.
  static isChildTooOld(child: Child, asOf?: Date) {
    return child.getAgeInYears(asOf) >= maxAgeInYearsForDevelopmentalScreening
  }

  get isSwyc() {
    // If !is Swyc, then is WAG.
    return this.modelName.startsWith("Swyc")
  }

  // Is this assessment old enough that we should prevent edits to it?
  get isEditable() {
    return isAssessmentEditable(this)
  }

  getOptionsForField(fieldName: string) {

    if (this.isSwyc) {
      const options = devScreeningContent.getSwycOptions()
      // Behavior
      if (fieldName.startsWith("bpsc") || fieldName.startsWith("ppsc")) {
        return options.behavior
      }
      // Development
      if (fieldName.startsWith("dmQ")) {
        return options.developmental
      }
      // Parent concerns
      if (fieldName.startsWith("pcQ")) {
        return options.behavior
      }
      // Family
      if (fieldName.startsWith("fq")) {
        // (This is just for code-folding purposes)
        if (["fqQ1", "fqQ2", "fqQ3", "fqQ4"].includes(fieldName)) {
          return options.yesNo
        }
        switch (fieldName) {
          case "fqQ5":
            return options.familyQ5
          case "fqQ6":
          case "fqQ7":
            return options.familyDepression
          case "fqQ8":
            return options.familyQ8
          case "fqQ9":
            return options.familyQ9
          case "fqQ10":
            return options.familyQ10
        }
      }
      if (fieldName.startsWith("ecwanb")) {
        return options[fieldName]
      }
    }
    else {
      const options = devScreeningContent.getWagOptions()
      if (fieldName === "cf16" && this.isWag2to4) {
        return options.amounts
      }
      if (["cf1", "cf4", "cf7"].includes(fieldName)) {
        return options.yesNo
      }
      if (["cf23", "cf24"].includes(fieldName)) {
        // (5 to 17 only)
        return options.frequencies
      }
      return options.difficulties
      // DIFFICULTIES: [
      //   "cf2", "cf3", "cf5", "cf6", "cf8", "cf9", "cf10",
      //   "cf11", "cf12", "cf13", "cf14", "cf15", "cf16",
      //   "cf17", "cf18", "cf19", "cf20", "cf21", "cf22"
    }
  }

  getResponse(fieldName: string) {
    const options = this.getOptionsForField(fieldName)
    return options[this[fieldName]]
  }

  get reportCard(): { stoplightColor: string, statements: Array<string> } {
    return this.isSwyc ? this.getSwycReportCard() : this.getWagReportCard()
  }

  getReferrals() {
    return this.isSwyc ? this.getSwycReferrals() : this.getWagReferrals()
  }

  // Return the date by which we would like a referral to be made. We're saying
  // two weeks from the date of assessment. Note: this doesn't check to see *if*
  // a referral was made.
  get referralDate() {
    return dayjs(this.dateOfAssessment).add(2, "week").toDate()
  }


  getResponsesByDomain() {
    return this.isSwyc ? this.getSwycResponsesByDomain() : this.getWagResponsesByDomain()
  }

  // Return an array of objects with props: id ("development"), label ("Development"), isComplete (true)
  getCategoriesAndStatuses() {
    return this.isSwyc ? this.getSwycCategoriesAndStatuses() : this.getWagCategoriesAndStatuses()
  }

  isCategoryComplete(fields: Array<string>): boolean {
    return fields.every(f => isKnown(this[f]))
  }

  // ================
  // = SWYC methods =
  // ================
  get developmentalMilestonesFieldNames() {
    return range(1, 11).map(i => `dmQ${i}`)
  }
  get developmentalMilestonesResponses() {
    return this.developmentalMilestonesFieldNames.map(f => this[f])
  }

  get babyPediatricSymptomChecklistFieldNames() {
    return range(1, 13).map(i => `bpscQ${i}`)
  }
  get babyPediatricSymptomChecklistResponses() {
    return this.babyPediatricSymptomChecklistFieldNames.map(f => this[f])
  }

  get preschoolPediatricSymptomChecklistFieldNames() {
    return range(1, 19).map(i => `ppscQ${i}`)
  }

  get preschoolPediatricSymptomChecklistResponses() {
    return this.preschoolPediatricSymptomChecklistFieldNames.map(f => this[f])
  }

  get familyFieldNames() {
    const fqNumbers = this.usesEmotionalChanges ? range(1, 11).filter(x => ![6, 7].includes(x)) : range(1, 11)
    return ["pcQ1", "pcQ2"].concat(fqNumbers.map(x => `fqQ${x}`))
  }
  get familyResponses() {
    return this.familyFieldNames.map(f => this[f])
  }

  get emotionalChangesFieldNames() {
    return range(1, 11).map(x => `ecwanbQ${x}`)
  }
  get emotionalChangesResponses() {
    return this.emotionalChangesFieldNames.map(f => this[f])
  }

  get familyFullFieldNames() {
    const fieldNames = this.familyFieldNames
    if (this.usesEmotionalChanges) {
      fieldNames.push(...this.emotionalChangesFieldNames)
    }
    return fieldNames
  }

  getSwycReferrals() {
    // NOTE: we aren't translating this content because we're being clever with it and it is (atm) country-specific.
    const results = []
    if (this.doDevelopmentalMilestonesNeedReview()) {
      results.push("Refer to doctor to take a closer look at the child's developmental milestones.")
    }
    const socialServicesReasons = []
    const childProtectionReasons = []
    if (this.fqQ5 && this.fqQ5 > 1) {
      results.push("Refer to agriculture extension services for further evaluation of food insecurity risk.")
      socialServicesReasons.push("food insecurity")
    }
    if (this.fqQ2 || this.fqQ3 || this.fqQ4) {
      socialServicesReasons.push("substance abuse")
      childProtectionReasons.push("substance abuse")
    }
    if (this.fqQ8 == 3 || this.fqQ9 == 3) {
      socialServicesReasons.push("domestic abuse")
      childProtectionReasons.push("domestic abuse")
    }
    if (childProtectionReasons.length) {
      results.push(
        "Refer to child protection services for further evaluation of the following risks: " +
        childProtectionReasons.join(", ")
      )
    }
    if (socialServicesReasons.length) {
      results.push(
        "Refer to social services for further evaluation of the following risks: " +
        socialServicesReasons.join(", ")
      )
    }
    return Array.from(results.entries()).map(([id, content]) => { return { id, content } })
  }

  getSwycReportCard(): { stoplightColor: string, statements: Array<string> } {
    const results = { stoplightColor: "", statements: [] }
    // Assumes this is a completed assessment!
    const verdicts = [
      this.doDevelopmentalMilestonesNeedReview(),
      this.doesFamilyNeedReview(),
    ]
    if (this.behaviorWasCompleted) {
      verdicts.push(this.doesBehaviorNeedReview())
    }
    if (verdicts.some(x => x)) {
      results.stoplightColor = "red"
      results.statements = [$gettext("Child is at risk for developmental or behavioral challenges.")]
    }
    else {
      results.stoplightColor = "green"
      results.statements = [$gettext("Child appears to meet age expectations and is growing in a favorable family environment.")]
    }
    return results
  }

  // Assuming we're using the SWYC, return true if this uses the BPSC;
  // false means we're using the PPSC.
  get usesBabyPediatricSymptomChecklist() {
    return this.modelName !== "Swyc18Months" && this.modelName !== "Swyc24Months"
  }

  // Assuming we're using the SWYC, return true if this uses the Emotional Changes questions;
  // false means we're using the long version of the Family Questions.
  get usesEmotionalChanges() {
    return ["Swyc2Months", "Swyc4Months", "Swyc6Months"].includes(this.modelName)
  }

  // Return true if the Developmental Milestones portion of the assessment
  // indicates "Needs Review".
  doDevelopmentalMilestonesNeedReview() {
    // These represent the minimum scores for each completed month for
    // a child's age.
    const cutoffs = {
      4: 13,
      5: 15,
      6: 11,
      7: 14,
      8: 16,
      9: 11,
      10: 13,
      11: 14,
      12: 12,
      13: 13,
      14: 14,
      15: 10,
      16: 12,
      17: 13,
      18: 8,
      19: 10,
      20: 11,
      21: 13,
      22: 14,
      23: 10,
      24: 11,
      25: 12,
      26: 13,
      27: 14,
      28: 15,
    }
    const cutoff = cutoffs[Math.floor(this.ageInMonths)]
    if (!cutoff) {
      // This really should only be the 2-month SWYC, which doesn't have cutoffs
      return false
    }
    const score = sum(this.developmentalMilestonesResponses)
    return score <= cutoff
  }

  getBehaviorSubscaleScore(start: number): number {
    // Return the sum of the values of the responses to the four questions
    // starting with question @start.
    const responses = range(start, start + 4).map(i => this[`bpscQ${i}`] || 0)
    return sum(responses)
  }

  doesBehaviorNeedReview(): boolean {
    if (this.usesBabyPediatricSymptomChecklist) {
      return this.doesBpscBehaviorNeedReview()
    }
    else {
      return this.doesPpscBehaviorNeedReview()
    }
  }

  // (For completed assessments, this could be false if it was optional at the
  // site level.)
  get behaviorWasCompleted(): boolean {
    return Boolean(isKnown(this.bpscQ1) || isKnown(this.ppscQ1))
  }

  /* Return true if child is "at risk". From the SWYC User Manual:
   *
   * 1. The BPSC is divided into three subscales, each with 4 items. Determine the BPSC
   * subscale scores by assigning a “0” for each “Not at All” response, a “1” for each
   * “Somewhat” response, and a “2” for each “Very Much” response, and then sum the results.
   *
   * 2. Any summed score of 3 or more on any of the three subscales indicates that a child
   * is “at risk” and needs further evaluation or investigation.
   *
   * [The subscales are:
   * - questions 1-4,
   * - questions 5-8,
   * - questions 9-12]
   */
  doesBpscBehaviorNeedReview(): boolean {
    return Boolean(
      this.getBehaviorSubscaleScore(1) >= 3 ||
      this.getBehaviorSubscaleScore(5) >= 3 ||
      this.getBehaviorSubscaleScore(9) >= 3
    )
  }

  /*
  * Return True if child is "at risk". From the SWYC User Manual:
  *
  * 1. Determine the PPSC total score by assigning a “0” for each “Not at All”
  *    response, a “1” for each “Somewhat” response, and a “2” for each “Very Much”
  *    response, and then sum the results.
  * 2. A PPSC total score of 9 or greater indicates that a child is "at risk" and
  *    needs further evaluation or investigation.
  */
  doesPpscBehaviorNeedReview(): boolean {
    const totalScore = sum(this.preschoolPediatricSymptomChecklistResponses)
    return totalScore >= 9
  }

  // Return an array of strings representing behavioral/emotional areas of concern
  getBehaviorAreasOfConcern(): Array<string> {
    const results = []
    if (this.usesBabyPediatricSymptomChecklist) {
      if (this.getBehaviorSubscaleScore(1) >= 3) {
        results.push($gettext("Irritability"))
      }
      if (this.getBehaviorSubscaleScore(5) >= 3) {
        results.push($gettext("Inflexibility"))
      }
      if (this.getBehaviorSubscaleScore(9) >= 3) {
        results.push($gettext("Difficulty establishing routines"))
      }
    }
    else if (this.doesPpscBehaviorNeedReview()) {
      results.push($gettext("Emotional or behavioral challenges"))
    }
    return results
  }
  getBehaviorRecommendations() {
    if (this.doesBehaviorNeedReview()) {
      return [$gettext("Discuss the child's behavior with the parent to learn more.")]
    }
  }

  getBehaviorCounselingRecommendations() {
    const results = []
    if (this.usesBabyPediatricSymptomChecklist) {
      if (this.getBehaviorSubscaleScore(1) >= 3 || this.getBehaviorSubscaleScore(5) >= 3) {
        results.push($gettext("Supporting children's emotional development"))
      }
      if (this.getBehaviorSubscaleScore(1) >= 3) {
        results.push($gettext("Responsive caregiving"))
      }
      if (this.getBehaviorSubscaleScore(9) >= 3) {
        results.push($gettext("Establishing routines"))
      }
    }
    else if (this.doesPpscBehaviorNeedReview()) {
      results.push($gettext("Supporting children's emotional development"))
      results.push($gettext("Responsive caregiving"))
    }
    return results
  }

  /*
   * Return true if child is "at risk" based on *just* the Family Questions.
   *
   * (That is, parents' concerns are accounted for elsewhere.)
   */
  doesFamilyNeedReview() {
    const substanceFields = ["fqQ1", "fqQ2", "fqQ3", "fqQ4"]
    const substanceStatus = substanceFields.some(prop => this[prop])
    const foodSecurityStatus = [2, 3].includes(this.fqQ5)
    const domesticViolenceStatus = [this.fqQ8, this.fqQ9].includes(3)
    return (
      substanceStatus ||
      foodSecurityStatus ||
      this.getDepressionStatus() ||
      domesticViolenceStatus
    )
  }

  getDepressionStatus() {
    // Younger infants get the "Emotional changes" questionaire
    if (this.usesEmotionalChanges) {
      const score = sum(range(1, 11).map(x => this[`ecwanbQ${x}`] || 0))
      return score >= 10
    }
    // Older children just get two extras in the "Family Questions"
    else {
      const total = (this.fqQ6 || 0) + (this.fqQ7 || 0)
      return total >= 3
    }
  }

  getFamilyRiskAreas(by_code = false) {
    // Return a list of risk areas, appropriate for bullets.
    const results = []
    if (this.fqQ1) {
      results.push(by_code ? "tobacco" : $gettext("Tobacco smoke exposure"))
    }
    if (this.fqQ2 || this.fqQ3 || this.fqQ4) {
      results.push(by_code ? "substance_abuse" : $gettext("Substance abuse"))
    }
    if (this.fqQ5 && this.fqQ5 > 1) {
      results.push(by_code ? "food_insecurity" : $gettext("Food insecurity"))
      if (this.fqQ8 === 3 || this.fqQ9 === 3) {
        results.push(by_code ? "domestic_violence" : $gettext("Domestic violence"))
      }
      if (this.getDepressionStatus()) {
        results.push(by_code ? "parental_depression" : $gettext("Parental depression"))
      }
      return results
    }
  }

  getFamilyCounselingRecommendations() {
    // Return a list of 2-tuples: (recommendation phrase, (optional) Learn More content).
    // If no risk, return empty list.
    const results = []
    if (this.fqQ1) {
      results.push({
        rec: $gettext("Tobacco smoke's impact on children's health."),
        learnMore: $gettext("Secondhand smoke can cause serious health problems in infants and children. Children living with family members who smoke get sick more often. They grow up with weak lungs and are likely get more bronchitis and pneumonia. Wheezing, coughing, shortness of breath, and ear infections are more common in children exposed to secondhand smoke. To potect the child from secondhand smoke, do not allow anyone to smoke in your home. Avoid taking your child to public areas where smoking is allowed or common.")
      })
    }
    if (this.fqQ5 && this.fqQ5 > 1) {
      results.push({
        rec: $gettext("Food diversity and nutrient-dense foods."),
        learnMore: $gettext("Nutrient-dense foods provide high amounts of protein, healthy fats, fiber, vitamins, and minerals compared to the amount of calories they supply. It is important to offer children a wide variety of nutrient-dense foods such as poultry, meat, liver, fish, eggs, nuts, seeds, dairy products, beans, fruits, and vegetables. To promote weight gain, select the foods that also provide a lot of calories such as meat, fish, eggs, nuts, seeds, and dairy products.")
      })
    }
    if (this.fqQ10 !== null && this.fqQ10 < 3) {
      results.push({
        rec: $gettext("The importance of reading or storytelling for children."),
        learnMore: $gettext("Reading aloud to children as early as 2 months of age teaches them about communication; helps them get to know sounds, words, and language; and builds their literacy skills. Storytelling is an alternative to reading. The more stories caregivers read aloud or tell, the more words children hear and the better they will learn to talk.")
      })
    }

    if (this.getDepressionStatus()) {
      if (this.usesEmotionalChanges) {
        results.push({
          rec: $gettext("The importance of seeking support for depression."),
          learnMore: $gettext("Postpartum depression is a common complication of childbearing. Most new mothers experience mild mood changes during or during the first 2-3 weeks after giving birth. This is a normal adjustment period. However, some women-regardless of culture, age, income level, and race-experience more significant symptoms of depression, anxiety, or mood disorders. Symptoms can appear any time during pregnancy and the first 12 months after childbirth. These mothers might cope with their baby and with household tasks, but their enjoyment of life is seriously affected, and it is possible that there are long-term effects on the family. The mental health and well-being of caregivers impact their ability to provide nurturing care to their children."),
        })
      }
      else {
        results.push({
          rec: $gettext("The importance of seeking support for depression."),
          learnMore: $gettext("The mental health and well-being of caregivers impact their ability to provide nurturing care to their children."),
        })
      }
    }
    return results
  }

  getCaregiverReferrals() {
    // Return a list of topics related to referrals for the caregiver themself.
    // (Not presented as a top-level referral, since it's not for the child directly.)
    const results = []
    if (this.getDepressionStatus()) {
      results.push($gettext("Mental health services"))
    }
    if (this.fqQ8 === 3 || this.fqQ9 === 3 || [this.fqQ2, this.fqQ3, this.fqQ4].some(x => x)) {
      results.push($gettext("Social services"))
    }
    return results
  }

  // SWYC Recommendations content
  get developmentStoplightColor() {
    return this.doDevelopmentalMilestonesNeedReview() ? "red" : "green"
  }
  get developmentSummaryStatement() {
    if (this.doDevelopmentalMilestonesNeedReview()) {
      return $gettext("Child may be at risk for developmental delay.")
    }
    else {
      return $gettext("Child appears to meet expectations for age.")
    }
  }
  get behaviorStoplightColor() {
    return this.doesBehaviorNeedReview() ? "red" : "green"
  }
  get behaviorSummaryStatement() {
    if (this.doDevelopmentalMilestonesNeedReview()) {
      return $gettext("Compared to other children the same age, child appears to be at risk for emotional or behavioral challenges.")
    }
    else {
      return $gettext("Child appears to meet expectations for age.")
    }
  }
  get familyStoplightColor() {
    const familyRisk = this.doesFamilyNeedReview()
    const parentsConcerned = Boolean(this.pcQ1 || this.pcQ2)
    return familyRisk || parentsConcerned ? "red" : "green"
  }
  get familySummaryStatement() {
    const parentsConcerned = Boolean(this.pcQ1 || this.pcQ2)
    const famStatements = []
    if (this.doesFamilyNeedReview()) famStatements.push($gettext("The child's family environment might put child at risk."))
    if (parentsConcerned) famStatements.push($gettext("Caregiver has concerns."))
    if (!famStatements.length) return $gettext("No risk in the child's family environment was identified.")
    else return famStatements.join(" ")
  }

  getSwycResponsesByDomain() {
    return [
      { domain: $gettext("Development"), responses: this.getDevelopmentalMilestonesInputs() },
      { domain: $gettext("Behavior & Emotion"), responses: this.getBehaviorInputs() },
      { domain: $gettext("Family"), responses: this.getFamilyInputs() },
    ]
  }

  getDevelopmentalMilestonesInputs() {
    function getResponseClasses(value) {
      switch (value) {
        case 0:
          return "highlight red"
        case 1:
          return "highlight yellow"
      }
    }
    const labels = devScreeningContent.getDevelopmentalMilestonesQuestions()[this.modelName]
    return this.developmentalMilestonesFieldNames.map(fieldName => {
      return {
        field: labels[fieldName],
        response: this.getResponse(fieldName) || $gettext("N/A"),
        responseClasses: getResponseClasses(this[fieldName]),
      }
    })
  }

  getBehaviorInputs() {
    function getResponseClasses(value) {
      switch (value) {
        case 2:
          return "highlight red"
        case 1:
          return "highlight yellow"
      }
    }
    const usesBpsc = this.usesBabyPediatricSymptomChecklist
    const labels = devScreeningContent.getBehaviorQuestions()
    const fieldNames = usesBpsc ? this.babyPediatricSymptomChecklistFieldNames : this.preschoolPediatricSymptomChecklistFieldNames
    return fieldNames.map(fieldName => {
      return {
        field: labels[fieldName],
        response: this.getResponse(fieldName) || $gettext("N/A"),
        responseClasses: getResponseClasses(this[fieldName]),
      }
    })
  }

  getFamilyInputs() {
    function getResponseClasses(fieldName: string, response: number) {
      if (fieldName.startsWith("ecwanb")) {
        switch (response) {
          case 3:
          case 4:
            return "highlight red"
          case 2:
            return "highlight yellow"
        }
        return ""
      }

      if (["fqQ1", "fqQ2", "fqQ3", "fqQ4"].includes(fieldName)) {
        return response ? "highlight red" : ""
      }
      if (fieldName === "fqQ5") {
        if (response === 2) {
          return "highlight yellow"
        }
        if (response === 3) {
          return "highlight red"
        }
        return ""
      }
      if (["fqQ6", "fqQ7", "pcQ1", "pcQ2"].includes(fieldName)) {
        if (response == 1) {
          return "highlight yellow"
        }
        if (response > 1) {
          return "highlight red"
        }
        return ""
      }
      if (["fqQ8", "fqQ9"].includes(fieldName)) {
        if (response === 2) {
          return "highlight yellow"
        }
        if (response > 2) {
          return "highlight red"
        }
        return ""
      }
      if (fieldName === "fqQ10") {
        return response <= 2 ? "highlight yellow" : ""
      }
    }

    const fqLabels = devScreeningContent.getFamilyQuestions()
    const ecLabels = devScreeningContent.getEmotionalChangesQuestions()
    const fieldNames = this.familyFieldNames
    if (this.usesEmotionalChanges) {
      fieldNames.push(...this.emotionalChangesFieldNames)
    }
    const results = this.familyFullFieldNames.map(fieldName => {
      return {
        field: fqLabels[fieldName] || ecLabels[fieldName],
        response: this.getResponse(fieldName) || $gettext("N/A"),
        responseClasses: getResponseClasses(fieldName, this[fieldName]),
      }
    })
    return results
  }

  // Return an array of objects with props: id ("development"), label ("Development"), isComplete (true)
  getSwycCategoriesAndStatuses() {
    const results = [{
      id: "development",
      label: $gettext("Developmental Milestones"),
      isComplete: this.isCategoryComplete(this.developmentalMilestonesFieldNames),
    }]
    const behavFieldNames = this.usesBabyPediatricSymptomChecklist ? this.babyPediatricSymptomChecklistFieldNames : this.preschoolPediatricSymptomChecklistFieldNames
    results.push({
      id: "behavior",
      label: $gettext("Behavior & Emotion"),
      isComplete: this.isCategoryComplete(behavFieldNames),
    })
    results.push({
      id: "family",
      label: $gettext("Family"),
      isComplete: this.isCategoryComplete(this.familyFieldNames),
    })
    if (this.usesEmotionalChanges) {
      results.push({
        id: "emotionalChanges",
        label: $gettext("Emotional Changes with a New Baby"),
        isComplete: this.isCategoryComplete(this.emotionalChangesFieldNames),
      })
    }
    return results
  }

  // ==================================
  // = Washington Group (WAG) methods =
  // ==================================
  getWagReportCard(): { stoplightColor: string, statements: Array<string> } {
    // Assumes this is a completed assessment!
    if (this.getWagInterpretations().length) {
      return {
        stoplightColor: "red",
        statements: [$gettext("Child is at risk for developmental or behavioral challenges.")]
      }
    }
    else {
      return {
        stoplightColor: "green",
        statements: [$gettext("Child appears to meet age expectations and is growing in a favorable family environment.")]
      }
    }
  }

  get wagStoplightColor() {
    return this.getWagInterpretations().length ? "red" : "green"
  }

  getWagReferrals() {
    const hasRisk = Boolean((this.getWagInterpretations().length))
    return hasRisk ? [{ id: 1, content: $gettext("Refer child to primary care doctor for further assessment of developmental concerns.") }] : []
  }

  // Return true if this is the earlier WAG questions. False means it's
  // the 5 to 17 version.
  get isWag2to4() {
    return this.modelName === "WashingtonGroup2to4Years"
  }

  getWagInterpretations() {
    if (this.isWag2to4) return this.getWag2to4Interpretations()
    else return this.getWag5to17Interpretations()
  }
  // Return a list dicts: area, interpretation, learn_more
  getWag2to4Interpretations() {
    const results = []
    if (this.cf2 === 3 || this.cf2 === 4) {
      results.push({
        area: $gettext("Seeing"),
        code: "seeing",
        interpretation: $gettext("The child may need new glasses or may have other difficulties that impact the ability to see. "),
      })
    }
    else if (this.cf3 === 3 || this.cf3 === 4) {
      results.push({
        area: $gettext("Seeing"),
        code: "seeing",
        interpretation: $gettext("The child may have difficulty seeing."),
        learnMore: $gettext("Seeing difficulties include problems seeing things in day or night, close up or far away, reduced ability to see out of one or both eyes, and limited peripheral vision."),
      })
    }
    if (this.cf5 === 3 || this.cf5 === 4) {
      results.push({
        area: $gettext("Hearing"),
        code: "hearing",
        interpretation: $gettext("The child's hearing aid might be faulty or not helping; or the child may have additional hearing difficulties."),
        learnMore: $gettext("Hearing difficulty includes hearing loss, reduced hearing in one or both ears, the inability to hear in a noisy or quiet environment or to distinguish sounds from different sources. "),
      })
    }
    else if (this.cf6 === 3 || this.cf6 === 4) {
      results.push({
        area: $gettext("Hearing"),
        code: "hearing",
        interpretation: $gettext("The child may have difficulty hearing."),
        learnMore: $gettext("Hearing difficulty includes hearing loss, reduced hearing in one or both ears, the inability to hear in a noisy or quiet environment or to distinguish sounds from different sources. "),
      })
    }
    if (this.cf8 === 3 || this.cf8 === 4) {
      results.push({
        area: $gettext("Mobility"),
        code: "mobility",
        interpretation: $gettext("The child has difficulty with mobility without equipment or assistance."),
        learnMore: $gettext("Walking is a good measure of gross motor skills because it requires a mix of strength, balance and the ability to control body movements. A child's inability to walk maybe due to some underlying medical problems."),
      })
    }
    else if (this.cf9 === 3 || this.cf9 === 4) {
      results.push({
        area: $gettext("Mobility"),
        code: "mobility",
        interpretation: $gettext("The child may need a different type of equipment or assistance for walking or may have additional difficulties with mobility."),
        learnMore: $gettext("Walking is a good measure of gross motor skills because it requires a mix of strength, balance and the ability to control body movements. A child's inability to walk maybe due to some underlying medical problems."),
      })
    }
    else if (this.cf10 === 3 || this.cf10 === 4) {
      results.push({
        area: $gettext("Mobility"),
        code: "mobility",
        interpretation: $gettext("The child may have difficulty with mobility. This can be due to an underlying medical condition or impairment."),
        learnMore: $gettext("Walking is a good measure of gross motor skills because it requires a mix of strength, balance and the ability to control body movements. A child's inability to walk maybe due to some underlying medical problems."),
      })
    }
    if (this.cf11 === 3 || this.cf11 === 4) {
      results.push({
        area: $gettext("Fine Motor"),
        code: "fine_motor",
        interpretation: $gettext("The child may have difficulty with fine motor skills."),
        learnMore: $gettext("Picking up small objects (such as a marble, button or small stone) is a good measure of basic fine motor skills because the task requires a mix of grip strength, motor control and dexterity."),
      })
    }
    if (this.cf12 === 3 || this.cf12 === 4 || this.cf13 === 3 || this.cf13 === 4) {
      results.push({
        area: $gettext("Communication and Comprehension"),
        code: "communication",
        interpretation: $gettext("The child may have difficulty with communication and comprehension."),
      })
    }
    if (this.cf14 === 3 || this.cf14 === 4) {
      results.push({
        area: $gettext("Learning"),
        code: "learning",
        interpretation: $gettext("The child may have difficulty with learning."),
        learnMore: $gettext("Difficulty learning compared to children of the same age may indicate a learning disorder."),
      })
    }
    if (this.cf15 === 3 || this.cf15 === 4) {
      results.push({
        area: $gettext("Playing"),
        code: "playing",
        interpretation: $gettext("The child has difficulty playing, which may be due to one or more functional difficulties like seeing, mobility, and communication."),
        learnMore: $gettext("Playing is a complex domain since it involves several functional capacities – from seeing to cognition. It is also heavily influenced by the child’s environment. Play is recognized to be one of the most important activities for the development of young children. Whether alone or in the company of others, a child’s ability to play is a building block to the development of social, emotional, cognitive and physical skills."),
      })
    }
    if (this.cf16 === 4) {
      results.push({
        area: $gettext("Controlling Behavior"),
        code: "behavior",
        interpretation: $gettext("The child may have difficulty with controlling behavior. This may limit the child's ability to interact with others in an appropriate manner."),
        learnMore: $gettext("All children may exhibit some behavioral difficulties at one time or another. However, behavioral issues that are reported as excessive by the primary caregiver and that limit the child's ability to apporpriately interact with other people should be further evaluated. For young children, this can include kicking, biting and hitting. For older children, this can include telling lies, fighting, bullying, running away from home, or skipping school."),
      })
    }
    return results
  }

  // Return a list dicts: area, interpretation, learn_more"""
  getWag5to17Interpretations() {
    const results = []
    if (this.cf2 === 3 || this.cf2 === 4) {
      results.push({
        area: $gettext("Seeing"),
        code: "seeing",
        interpretation: $gettext("The child may need new glasses or may have other difficulties that impact the ability to see. "),
      })
    }
    else if (this.cf3 === 3 || this.cf3 === 4) {
      results.push({
        area: $gettext("Seeing"),
        code: "seeing",
        interpretation: $gettext("The child may have difficulty seeing."),
        learnMore: $gettext("Seeing difficulties include problems seeing things in day or night, close up or far away, reduced ability to see out of one or both eyes, and limited peripheral vision."),
      })
    }
    if (this.cf5 === 3 || this.cf5 === 4) {
      results.push({
        area: $gettext("Hearing"),
        code: "hearing",
        interpretation: $gettext("The child's hearing aid might be faulty or not helping; or the child may have additional hearing difficulties."),
        learnMore: $gettext("Hearing difficulty includes hearing loss, reduced hearing in one or both ears, the inability to hear in a noisy or quiet environment or to distinguish sounds from different sources. "),
      })
    }
    else if (this.cf6 === 3 || this.cf6 === 4) {
      results.push({
        area: $gettext("Hearing"),
        code: "hearing",
        interpretation: $gettext("The child may have difficulty hearing."),
        learnMore: $gettext("Hearing difficulty includes hearing loss, reduced hearing in one or both ears, the inability to hear in a noisy or quiet environment or to distinguish sounds from different sources. "),
      })
    }
    // Mobility–there are a bunch
    if (this.cf8 === 3 || this.cf8 === 4 || this.cf9 === 3 || this.cf9 === 4) {
      results.push({
        area: $gettext("Mobility"),
        code: "mobility",
        interpretation: $gettext("The child has difficulty with mobility without equipment or assistance."),
        learnMore: $gettext("Walking is a good measure of gross motor skills because it requires a mix of strength, balance and the ability to control body movements. A child's inability to walk maybe due to some underlying medical problems."),
      })
    }
    else if (this.cf10 === 3 || this.cf10 === 4 || this.cf11 === 3 || this.cf11 === 4) {
      results.push({
        area: $gettext("Mobility"),
        code: "mobility",
        interpretation: $gettext("The child may need a different type of equipment or assistance for walking or may have additional difficulties with mobility."),
        learnMore: $gettext("Walking is a good measure of gross motor skills because it requires a mix of strength, balance and the ability to control body movements. A child's inability to walk maybe due to some underlying medical problems."),
      })
    }
    else if (this.cf12 === 3 || this.cf12 === 4 || this.cf13 === 3 || this.cf13 === 4) {
      results.push({
        area: $gettext("Mobility"),
        code: "mobility",
        interpretation: $gettext("The child may have difficulty with mobility. This can be due to an underlying medical condition or impairment."),
        learnMore: $gettext("Walking is a good measure of gross motor skills because it requires a mix of strength, balance and the ability to control body movements. A child's inability to walk maybe due to some underlying medical problems."),
      })
    }
    if (this.cf14 === 3 || this.cf14 === 4) {
      results.push({
        area: $gettext("Self-care"),
        code: "this_care",
        interpretation: $gettext("The child may have difficulty with this-care."),
      })
    }
    if (this.cf15 === 3 || this.cf15 === 4 || this.cf16 === 3 || this.cf16 === 4) {
      results.push({
        area: $gettext("Communication and Comprehension"),
        code: "communication",
        interpretation: $gettext("The child may have difficulty with communication and comprehension."),
      })
    }
    if (this.cf17 === 3 || this.cf17 === 4) {
      results.push({
        area: $gettext("Learning"),
        code: "learning",
        interpretation: $gettext("Child may have cognitive difficulties."),
        learnMore: $gettext("Difficulty learning compared to children of the same age may indicate a learning disorder."),
      })
    }
    if (this.cf18 === 3 || this.cf18 === 4) {
      results.push({
        area: $gettext("Remembering"),
        code: "remembering",
        interpretation: $gettext("Child may have cognitive difficulties."),
        learnMore: $gettext("Children with cognitive difficulties have a hard time remembering things. This may include remembering new people, songs and games, routines, etc., as well as the kind of learning that traditionally occurs within a school environment. Remembering things is not the same as memorizing. Remembering should not be translated as ‘memorizing’ or having good or bad memories."),
      })
    }
    if (this.cf19 === 3 || this.cf19 === 4) {
      results.push({
        area: $gettext("Attention and Concentrating"),
        code: "attention",
        interpretation: $gettext("Child may have cognitive difficulties. The child has difficulty with focusing attention."),
        learnMore: $gettext("All children may exhibit some behavioral difficulties at one time or another. However, behavioral issues that are reported as excessive by the primary caregiver and that limit the child's ability to apporpriately interact with other people should be further evaluated. For young children, this can include kicking, biting and hitting. For older children, this can include telling lies, fighting, bullying, running away from home, or skipping school."),
      })
    }
    if (this.cf20 === 3 || this.cf20 === 4) {
      results.push({
        area: $gettext("Accepting Change"),
        code: "accepting_change",
        interpretation: $gettext("The child has difficulty accepting change. This may be due to cognitive or emotional difficulties."),
        learnMore: $gettext("Children with cognitive or emotional difficulties may be resistant to change. They have difficulty transitioning from one activity to another on a consistent basis, and with changes to their routine, to the extent that it undermines their ability to participate in standard childhood activities.This is different than being stubborm, which many children can be at times."),
      })
    }
    if (this.cf21 === 3 || this.cf21 === 4) {
      results.push({
        area: $gettext("Controlling Behavior"),
        code: "behavior",
        interpretation: $gettext("The child may have difficulty with controlling behavior. This may limit the child's ability to interact with others in an appropriate manner."),
        learnMore: $gettext("All children may exhibit some behavioral difficulties at one time or another. However, behavioral issues that are reported as excessive by the primary caregiver and that limit the child's ability to apporpriately interact with other people should be further evaluated. For young children, this can include kicking, biting and hitting. For older children, this can include telling lies, fighting, bullying, running away from home, or skipping school."),
      })
    }
    if (this.cf22 === 3 || this.cf22 === 4) {
      results.push({
        area: $gettext("Relationships"),
        code: "relationships",
        interpretation: $gettext("The child might have emotional, behavioral, communication or cognitive difficulties."),
        learnMore: $gettext("Children who have difficulty socializing with other children may not be to participate in standard childhood activities. The ability to form relationships is an important indicator of typical development. Difficulties making friends may be the result of emotional, behavioral, communication or cognitive difficulties."),
      })
    }
    if (this.cf23 === 3 || this.cf23 === 4) {
      results.push({
        area: $gettext("Anxiety or Depression"),
        code: "anxiety",
        interpretation: $gettext("The child may be experiencing emotional difficulties, anxiety, or depression."),
        learnMore: $gettext("All children have some worries and may feel sad, but when these worries result in the child being restless, tired, inattentive, irritable, tense, or having sleep problems, they may interfere with schooling and social development. Emotional difficulties may manifest as worry, sadness, or anxiety, or they may be episodic in nature, but frequent enough and significant enough to place the child at a higher risk of dropping out of school, not participating in family or community life, or harming themselves."),
      })
    }
    return results
  }

  getWagResponsesByDomain() {
    let labels: object, domains: Array<object>, getResponseClasses: (screening: DevelopmentalScreening, fieldName: string) => string
    function getWag2to4ResponseClasses(screening: DevelopmentalScreening, fieldName: string) {
      switch (screening[fieldName]) {
        case "":
        case undefined:
        case null:
          return "muted"
        case 3:
          return fieldName !== "cf16" ? "highlight yellow" : ""
        case 4:
          return "highlight red"
      }
    }

    function getWag5to17ResponseClasses(screening: DevelopmentalScreening, fieldName: string) {
      if (fieldName === "cf23" || fieldName === "cf24") {
        switch (screening[fieldName]) {
          case "":
          case undefined:
          case null:
            return "muted"
          case 1:
            return "highlight red"
        }
      }
      else {
        switch (screening[fieldName]) {
          case "":
          case undefined:
          case null:
            return "muted"
          case 3:
            return "highlight yellow"
          case 4:
            return "highlight red"
        }
      }
      return ""
    }

    if (this.isWag2to4) {
      labels = devScreeningContent.getWag2to4Questions()
      domains = devScreeningContent.getWag2to4Domains()
      getResponseClasses = getWag2to4ResponseClasses
    }
    else {
      labels = devScreeningContent.getWag5to17Questions()
      domains = devScreeningContent.getWag5to17Domains()
      getResponseClasses = getWag5to17ResponseClasses
    }

    const results = []
    for (let x = 0; x < domains.length; x++) {
      const domain = domains[x]["domain"]
      const fields = domains[x]["fields"]
      const responses = []
      for (let y = 0; y < fields.length; y++) {
        const fieldName = fields[y]
        responses.push({
          field: labels[fieldName],
          response: this.getResponse(fieldName) || $gettext("N/A"),
          responseClasses: getResponseClasses(this, fieldName),
        })
      }
      results.push({ domain, responses })
    }
    return results
  }

  // Return an array of objects with props: id ("development"), label ("Development"), isComplete (true)
  getWagCategoriesAndStatuses() {
    // developmental domains:
    //    vision, hearing, mobility, fine motor, communication/comprehension,
    //    learning, remember, self-care questions
    // behavior & emotion:
    //    coping with change, playing, controlling behavior, making friends,
    //    attention/concentration, anxiety/depression
    const behaviorCompletionFields = []
    const developmentCompletionFields = [
      // Vision
      this.cf1, this.cf2 || this.cf3,
      // Hearing
      this.cf4, this.cf5 || this.cf6,
      this.isWagMobilityComplete(),
    ]
    if (this.isWag2to4) {
      developmentCompletionFields.push(
        // Dexterity
        this.cf11,
        // Communication
        this.cf12, this.cf13,
        // Learning
        this.cf14,
      )
      behaviorCompletionFields.push(
        // Playing
        this.cf15,
        // Controlling Behavior
        this.cf16,
      )
    }
    else {
      developmentCompletionFields.push(
        // Self-Care
        this.cf14,
        // Communication
        this.cf15, this.cf16,
        // Learning
        this.cf17,
        // Remembering
        this.cf18,
        // Concentrating
        this.cf19,
      )
      behaviorCompletionFields.push(
        // Accepting Change
        this.cf20,
        // Controlling Behavior
        this.cf21,
        // Making Friends
        this.cf22,
        // Anxiety
        this.cf23,
        // Depression
        this.cf24,
      )
    }
    const results = [
      {
        id: "wag-development",
        label: $gettext("Development"),
        isComplete: developmentCompletionFields.every(x => x),
      },
      {
        id: "wag-behavior",
        label: $gettext("Behavior & Emotion"),
        isComplete: behaviorCompletionFields.every(x => x),
      },
    ]
    return results
  }

  isWagMobilityComplete() {
    if (this.isWag2to4) {
      return [this.cf7, (this.cf8 && this.cf9) || this.cf10].every(x => x)
    }
    else {
      if (!this.cf7) return false
      if (this.cf8) {
        if ([2, 7, 9].includes(this.cf8)) {
          if (!this.cf9) return false
        }
        if (!this.cf10 || ([1, 2, 7, 9].includes(this.cf10) && !this.cf11)) return false
        return true
      }
      else if (this.cf12) {
        if ([1, 2, 7, 9].includes(this.cf12)) {
          return this.cf13 ? true : false
        }
        return true
      }
      return false
    }
  }

  getNextFieldNameAndPosition(type: string, fieldName: string) {
    if (type === "behavior") {
      if (this.isWag2to4) {
        switch (fieldName) {
          case "": return { fieldName: "cf15", categoryNumber: 1 }
          case "cf15": return { fieldName: "cf16", categoryNumber: 2 }
          case "cf16": return {}
        }
      }
      else {
        switch (fieldName) {
          case "": return { fieldName: "cf20", categoryNumber: 1 }
          case "cf20": return { fieldName: "cf21", categoryNumber: 2 }
          case "cf21": return { fieldName: "cf22", categoryNumber: 3 }
          case "cf22": return { fieldName: "cf23", categoryNumber: 4 }
          case "cf23": return { fieldName: "cf24", categoryNumber: 5 }
          case "cf24": return {}
        }
      }
    }
    else if (type === "development") {
      switch (fieldName) {
        // Vision
        case "": return { fieldName: "cf1", categoryNumber: 1 }
        case "cf1": return { fieldName: this.cf1 === 1 ? "cf2" : "cf3", categoryNumber: 1 }
        case "cf2":
        case "cf3": return { fieldName: "cf4", categoryNumber: 2 }
        // Hearing
        case "cf4": return { fieldName: this.cf4 === 1 ? "cf5" : "cf6", categoryNumber: 2 }
        case "cf5":
        case "cf6": return { fieldName: "cf7", categoryNumber: 3 }
      }
      if (this.isWag2to4) {
        switch (fieldName) {
          // Mobility
          case "cf7": return { fieldName: this.cf7 === 1 ? "cf8" : "cf10", categoryNumber: 3 }
          case "cf8": return { fieldName: "cf9", categoryNumber: 3 }
          case "cf9":
          case "cf10": return { fieldName: "cf11", categoryNumber: 4 }
          // Dexterity
          case "cf11": return { fieldName: "cf12", categoryNumber: 5 }
          // Communication
          case "cf12": return { fieldName: "cf13", categoryNumber: 5 }
          case "cf13": return { fieldName: "cf14", categoryNumber: 6 }
          // Learning
          case "cf14": return {}
        }
      }
      else {
        switch (fieldName) {
          // Mobility
          case "cf7": return { fieldName: this.cf7 === 1 ? "cf8" : "cf12", categoryNumber: 3 }
          case "cf8": return { fieldName: [3, 4].includes(this.cf8) ? "cf10" : "cf9", categoryNumber: 3 }
          case "cf9": return { fieldName: "cf10", categoryNumber: 3 }
          case "cf10": return { fieldName: [3, 4].includes(this.cf10) ? "cf14" : "cf11", categoryNumber: 1 }
          case "cf11": return { fieldName: "cf14", categoryNumber: 1 }
          case "cf12":
            if ([3, 4].includes(this.cf12)) return { fieldName: "cf14", categoryNumber: 4 }
            else return { fieldName: "cf13", categoryNumber: 3 }
          case "cf13": return { fieldName: "cf14", categoryNumber: 4 }
          // Self-care
          case "cf14": return { fieldName: "cf15", categoryNumber: 5 }
          // Communication
          case "cf15": return { fieldName: "cf16", categoryNumber: 5 }
          case "cf16": return { fieldName: "cf17", categoryNumber: 6 }
          // Learning
          case "cf17": return { fieldName: "cf18", categoryNumber: 7 }
          // Remembering
          case "cf18": return { fieldName: "cf19", categoryNumber: 8 }
          // Concentrating
          case "cf19": return {}
        }
      }
    }
  }

}
