<script lang="ts" setup>
import { ref } from "vue"
import { useRouter } from "vue-router"

import { setCurrentViewContext } from "@/utils/GlobalState"
import { logout } from "@/services/Account"
import { db } from "@/db"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext
const router = useRouter()
const showPendingWarning = ref(false)
const showOtherWarning = ref(false)
const otherError = ref(null)
const pendingCount = ref(0)

setCurrentViewContext()

async function tryLoggingOut() {
  showOtherWarning.value = false
  otherError.value = null
  logout()
    .then(() => router.push({ name: "Login" }))
    .catch(async (error) => {
      if (error.name === "LOGIN_REQUIRED") {
        // That's fine–probably at some point the server DID log the user out.
        // Don't make a big deal out of it; just proceed as if successful.
        router.push({ name: "Login" })
        return
      }
      if (error.name === "PENDING_UPLOADS") {
        showPendingWarning.value = true
        pendingCount.value = await db.pendingUploads.count()
      }
      else if (error.name === "CONNECTIVITY_REQUIRED") {
        showOtherWarning.value = true
      }
      else if (error === "SERVER_DOWN") {
        showOtherWarning.value = true
        otherError.value = $gettext("Something unexpected happened. You may try again later.")
      }
      else {
        showOtherWarning.value = true
        otherError.value = $gettext("Something unexpected happened. You may try again later.")
      }
    })
}

async function forceLogout() {
  await logout(true)
    .catch(() => null)
    .finally(() => router.push({ name: "Login" }))
}

tryLoggingOut()
</script>

<template>
  <article>
    <h1 class="title">
      {{ $gettext("Log Out") }}
    </h1>

    <template v-if="!showPendingWarning && !showOtherWarning">
      <progress
        class="progress is-large is-info"
        max="100"
      >
        60%
      </progress>
    </template>
    <template v-if="showPendingWarning">
      <div class="notification is-danger">
        <div class="bang">
          !
        </div>
        <p>{{ $gettext("Warning: information will be deleted!") }}</p>
      </div>

      <template v-if="pendingCount">
        <p class="block">
          <strong>
            {{
              $ngettext(
                "You have 1 item which has not been uploaded to the server.",
                "You have %{number} items which have not been uploaded to the server.",
                pendingCount,
                { number: pendingCount.toLocaleString() },
              )
            }}
          </strong>
          {{ $gettext("They will be deleted if you proceed.") }}
          <router-link
            :to="{name: 'PendingUploads' }"
            class="text-link"
          >
            {{ $gettext("You may review this content on the Pending page.") }}
          </router-link>
        </p>

        <p class="block">
          {{ $gettext("If these items are important, you may try the following:") }}
        </p>
        <ul
          class="block"
          style="margin-left: 1em; list-style: inherit"
        >
          <li>
            {{ $gettext("Connect your device to the internet, or go to a location with connectivity, to allow content to upload.") }}
          </li>
          <li>
            {{
              $gettext("Delay logging out until you are able to upload these items at a later time.")
            }}
          </li>
        </ul>

        <a
          class="button"
          @click="forceLogout"
        >{{ $gettext("Delete everything and log out") }}</a>
      </template>
      <template v-else>
        <div class="columns">
          <div class="column">
            <a
              class="button"
              @click="forceLogout"
            >{{ $gettext("Log out") }}</a>
          </div>
        </div>
      </template>
    </template>
    <template v-if="showOtherWarning">
      <p v-if="otherError">
        {{ otherError }}
      </p>
      <template v-else>
        <p>
          {{ $gettext("You do not appear to be online.") }}
          {{ $gettext("All data have been deleted from this device, so you may log out.") }}
        </p>
        <a
          class="button"
          @click="tryLoggingOut"
        >
          {{ $gettext("Log out") }}
        </a>
      </template>
    </template>
  </article>
</template>

<style scoped lang="scss">
.notification {
  display: flex;
  align-items: center;
  padding-right: 1.5rem; /* Same as left; no "delete" x to accommodate */
  p {
    padding: 0 0 0 0.5em;
    margin: 0;
  }
}
.bang {
  border-radius: 50%;
  color: black;
  border: 2px solid black;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  line-height: 1;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}
</style>
